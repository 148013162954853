<template>
    <div class="list">
        <div v-if='list.length>0'>
            <div class="item" v-for='(item,index) in list' :key='index' @click='(check == 1 || item.children.length>0) && navigator(item.org_code)'>
                <div class="img">
                    <img :src="imgName" alt="">
                </div>
                <div class="info">
                    <div class="name overnowrap" :class="check == 0?'lh40':'lhn'">{{item.org_name}}</div>
                    <div class="time" v-if='check == 1'>{{item.last_settlement_time}}</div>
                </div>
                <div class="right" v-if='check == 0'>{{item.total}}个<span class="symbol"  v-if='check == 0 &&  item.children && item.children.length>0'>&#155</span></div>
                <div class="right" v-if='check == 1'>￥{{item.settlement_fee > 0 ?Number(item.settlement_fee).formatMoney():"0.00"}}<span class="symbol"  v-if='check == 1'>&#155</span></div>
                <div class="clear"></div>
                <div class="line-wrapper" v-if='check == 0 && item.children && item.children.length>0'>
                    <div v-if='item.children.length > 3'>
                        <div class="line" v-for='(itemv,index1) in item.children.slice(0,3)' :key='index1'>
                            <div class="l-left">{{index1+1}}.{{itemv.org_name}}</div>
                            <div class="l-right">{{itemv.total}}个</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="line" v-for='(itemv,index1) in item.children' :key='index1'>
                            <div class="l-left">{{index1+1}}.{{itemv.org_name}}</div>
                            <div class="l-right">{{itemv.total}}个</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-else class="no-data">
            <img src="../assets/no-data.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "ItemsignatureList",
    props: {
        check: Number,
        imgName:String,
        list:Array
    },
    methods:{
        navigator(org_code){
            this.$emit('itemClick',org_code)
        },
     
    },
    mounted() {
      
    },
};
</script>