<template>
    <div class="lawfirm">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="签章费用" fixed placeholder safe-area-inset-top />
        <ShowTotal :dataJson='dataJson' />
        <van-tabs class="tab-top" v-model="active">
            <van-tab title="服务明细"></van-tab>
            <van-tab title="结算记录"></van-tab>
        </van-tabs>
        <ItemsignatureList :check='active' :list='list' :imgName='imgName' @itemClick="linkDetail"></ItemsignatureList>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs } from 'vant';
import ShowTotal from '@/components/ShowTotal'
import ItemsignatureList from '@/components/ItemsignatureList'
export default {
    name:"signature",
    data(){
        return{
            dataJson:{
                title:'结算总额',
                total:'0.00',
                frequencyName:"共使用次数：",
                frequencyVal:"0",
                numName:"结算次数：",
                numVal:"0",
            },
            active:0,
            imgName:require("../../assets/signature.png"),
            list:[]
        }
    },
     watch: {
        active(val) {
            if(val == 0) this.getService()
            else this.getDept()
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        linkDetail(org_code){
             if(this.active == 0){
                this.$router.push({path:'/signature/serviceDetail',query:{org_code:org_code}})
            }else{
                this.$router.push({path:'/signature/detail',query:{org_code:org_code}})
            }
        },
        getTotal(){
            this.$post(`/finance/api/cfcaFee/sealOverview`,{appid: "28f10c6186cc11ebb35f00163e089d08"}).then(res => {
                if (res.code === '200') {
                    console.info(res.data)
                    this.dataJson.total = Number(res.data.settlement_fee)
                    this.dataJson.frequencyVal = res.data.total_quantity
                    this.dataJson.numVal = res.data.settlement_quantity
                    this.getService()
                }
            })
        },
        getService(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$post(`/finance/api/cfcaFee/sealDetail`,{appid: "28f10c6186cc11ebb35f00163e089d08"}).then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    Toast.clear();
                }
            })
        },
        getDept(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$post(`/finance/api/cfcaFee/list`,{appid: "28f10c6186cc11ebb35f00163e089d08","param":{"settlementOnly": "true"}}).then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    Toast.clear();
                }
            })
        }
    },
    mounted() {
        this.getTotal()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        ShowTotal,
        ItemsignatureList
    }
}
</script>

<style lang="scss" scoped>
    .lawfirm{
        background: #F4F4F4;
        height: 100vh;
    }
</style>